import { default as aboutLuNkfCUhn2Meta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/about.vue?macro=true";
import { default as indexNVdi4fUGp0Meta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/cloth_orders/index.vue?macro=true";
import { default as _91id_9315G9pPe6NoMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/clothes/[id].vue?macro=true";
import { default as ___indexAyzfVWlEnMMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/clothes/___index.vue?macro=true";
import { default as indexJj4lSJYoqNMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/clothes/index.vue?macro=true";
import { default as commerceuwGTkFxLJnMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/commerce.vue?macro=true";
import { default as signupX3KUKQ6mmwMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/creators/signup.vue?macro=true";
import { default as clothes1IUPdN5yu7Meta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/favorites/clothes.vue?macro=true";
import { default as creatorsPwKPmX5hMeMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/favorites/creators.vue?macro=true";
import { default as productsiH1AZtTT3iMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/favorites/products.vue?macro=true";
import { default as indexUdWMQ3ObIDMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/index.vue?macro=true";
import { default as informationsTRf5Ns5XzsMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/informations.vue?macro=true";
import { default as inquirywJNFekHmUgMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/inquiry.vue?macro=true";
import { default as _91_46_46_46slug_93NjStttRiu4Meta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/member/[...slug].vue?macro=true";
import { default as notificationsiT1xWGQtS5Meta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/notifications.vue?macro=true";
import { default as order_messagesMMAH7ys9OZMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/order_messages.vue?macro=true";
import { default as privacyhSmPUz2YVJMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/privacy.vue?macro=true";
import { default as _91id_93Do52ucF8JcMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/product_orders/[id].vue?macro=true";
import { default as _91id_93Xg9jgT38w6Meta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/products/[id].vue?macro=true";
import { default as indexnoNCxus22FMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/products/index.vue?macro=true";
import { default as sewmeeUWhuuurTT5Meta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/sewmee.vue?macro=true";
import { default as signinXeDspQ7o5hMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/signin.vue?macro=true";
import { default as signoutSwGXlnxLwXMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/signout.vue?macro=true";
import { default as signupaqSlB33LrIMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/signup.vue?macro=true";
import { default as signupDfM7VvnKJqMeta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/suppliers/signup.vue?macro=true";
import { default as terms1vmjMdRxP9Meta } from "/var/repos/inter5/sewmee/appcnf/shop/pages/terms.vue?macro=true";
export default [
  {
    name: aboutLuNkfCUhn2Meta?.name ?? "about",
    path: aboutLuNkfCUhn2Meta?.path ?? "/about",
    meta: aboutLuNkfCUhn2Meta || {},
    alias: aboutLuNkfCUhn2Meta?.alias || [],
    redirect: aboutLuNkfCUhn2Meta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexNVdi4fUGp0Meta?.name ?? "cloth_orders",
    path: indexNVdi4fUGp0Meta?.path ?? "/cloth_orders",
    meta: indexNVdi4fUGp0Meta || {},
    alias: indexNVdi4fUGp0Meta?.alias || [],
    redirect: indexNVdi4fUGp0Meta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/cloth_orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9315G9pPe6NoMeta?.name ?? "clothes-id",
    path: _91id_9315G9pPe6NoMeta?.path ?? "/clothes/:id()",
    meta: _91id_9315G9pPe6NoMeta || {},
    alias: _91id_9315G9pPe6NoMeta?.alias || [],
    redirect: _91id_9315G9pPe6NoMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/clothes/[id].vue").then(m => m.default || m)
  },
  {
    name: ___indexAyzfVWlEnMMeta?.name ?? "clothes-___index",
    path: ___indexAyzfVWlEnMMeta?.path ?? "/clothes/___index",
    meta: ___indexAyzfVWlEnMMeta || {},
    alias: ___indexAyzfVWlEnMMeta?.alias || [],
    redirect: ___indexAyzfVWlEnMMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/clothes/___index.vue").then(m => m.default || m)
  },
  {
    name: indexJj4lSJYoqNMeta?.name ?? "clothes",
    path: indexJj4lSJYoqNMeta?.path ?? "/clothes",
    meta: indexJj4lSJYoqNMeta || {},
    alias: indexJj4lSJYoqNMeta?.alias || [],
    redirect: indexJj4lSJYoqNMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/clothes/index.vue").then(m => m.default || m)
  },
  {
    name: commerceuwGTkFxLJnMeta?.name ?? "commerce",
    path: commerceuwGTkFxLJnMeta?.path ?? "/commerce",
    meta: commerceuwGTkFxLJnMeta || {},
    alias: commerceuwGTkFxLJnMeta?.alias || [],
    redirect: commerceuwGTkFxLJnMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/commerce.vue").then(m => m.default || m)
  },
  {
    name: signupX3KUKQ6mmwMeta?.name ?? "creators-signup",
    path: signupX3KUKQ6mmwMeta?.path ?? "/creators/signup",
    meta: signupX3KUKQ6mmwMeta || {},
    alias: signupX3KUKQ6mmwMeta?.alias || [],
    redirect: signupX3KUKQ6mmwMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/creators/signup.vue").then(m => m.default || m)
  },
  {
    name: clothes1IUPdN5yu7Meta?.name ?? "favorites-clothes",
    path: clothes1IUPdN5yu7Meta?.path ?? "/favorites/clothes",
    meta: clothes1IUPdN5yu7Meta || {},
    alias: clothes1IUPdN5yu7Meta?.alias || [],
    redirect: clothes1IUPdN5yu7Meta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/favorites/clothes.vue").then(m => m.default || m)
  },
  {
    name: creatorsPwKPmX5hMeMeta?.name ?? "favorites-creators",
    path: creatorsPwKPmX5hMeMeta?.path ?? "/favorites/creators",
    meta: creatorsPwKPmX5hMeMeta || {},
    alias: creatorsPwKPmX5hMeMeta?.alias || [],
    redirect: creatorsPwKPmX5hMeMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/favorites/creators.vue").then(m => m.default || m)
  },
  {
    name: productsiH1AZtTT3iMeta?.name ?? "favorites-products",
    path: productsiH1AZtTT3iMeta?.path ?? "/favorites/products",
    meta: productsiH1AZtTT3iMeta || {},
    alias: productsiH1AZtTT3iMeta?.alias || [],
    redirect: productsiH1AZtTT3iMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/favorites/products.vue").then(m => m.default || m)
  },
  {
    name: indexUdWMQ3ObIDMeta?.name ?? "index",
    path: indexUdWMQ3ObIDMeta?.path ?? "/",
    meta: indexUdWMQ3ObIDMeta || {},
    alias: indexUdWMQ3ObIDMeta?.alias || [],
    redirect: indexUdWMQ3ObIDMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: informationsTRf5Ns5XzsMeta?.name ?? "informations",
    path: informationsTRf5Ns5XzsMeta?.path ?? "/informations",
    meta: informationsTRf5Ns5XzsMeta || {},
    alias: informationsTRf5Ns5XzsMeta?.alias || [],
    redirect: informationsTRf5Ns5XzsMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/informations.vue").then(m => m.default || m)
  },
  {
    name: inquirywJNFekHmUgMeta?.name ?? "inquiry",
    path: inquirywJNFekHmUgMeta?.path ?? "/inquiry",
    meta: inquirywJNFekHmUgMeta || {},
    alias: inquirywJNFekHmUgMeta?.alias || [],
    redirect: inquirywJNFekHmUgMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/inquiry.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93NjStttRiu4Meta?.name ?? "member-slug",
    path: _91_46_46_46slug_93NjStttRiu4Meta?.path ?? "/member/:slug(.*)*",
    meta: _91_46_46_46slug_93NjStttRiu4Meta || {},
    alias: _91_46_46_46slug_93NjStttRiu4Meta?.alias || [],
    redirect: _91_46_46_46slug_93NjStttRiu4Meta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/member/[...slug].vue").then(m => m.default || m)
  },
  {
    name: notificationsiT1xWGQtS5Meta?.name ?? "notifications",
    path: notificationsiT1xWGQtS5Meta?.path ?? "/notifications",
    meta: notificationsiT1xWGQtS5Meta || {},
    alias: notificationsiT1xWGQtS5Meta?.alias || [],
    redirect: notificationsiT1xWGQtS5Meta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: order_messagesMMAH7ys9OZMeta?.name ?? "order_messages",
    path: order_messagesMMAH7ys9OZMeta?.path ?? "/order_messages",
    meta: order_messagesMMAH7ys9OZMeta || {},
    alias: order_messagesMMAH7ys9OZMeta?.alias || [],
    redirect: order_messagesMMAH7ys9OZMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/order_messages.vue").then(m => m.default || m)
  },
  {
    name: privacyhSmPUz2YVJMeta?.name ?? "privacy",
    path: privacyhSmPUz2YVJMeta?.path ?? "/privacy",
    meta: privacyhSmPUz2YVJMeta || {},
    alias: privacyhSmPUz2YVJMeta?.alias || [],
    redirect: privacyhSmPUz2YVJMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Do52ucF8JcMeta?.name ?? "product_orders-id",
    path: _91id_93Do52ucF8JcMeta?.path ?? "/product_orders/:id()",
    meta: _91id_93Do52ucF8JcMeta || {},
    alias: _91id_93Do52ucF8JcMeta?.alias || [],
    redirect: _91id_93Do52ucF8JcMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/product_orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Xg9jgT38w6Meta?.name ?? "products-id",
    path: _91id_93Xg9jgT38w6Meta?.path ?? "/products/:id()",
    meta: _91id_93Xg9jgT38w6Meta || {},
    alias: _91id_93Xg9jgT38w6Meta?.alias || [],
    redirect: _91id_93Xg9jgT38w6Meta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexnoNCxus22FMeta?.name ?? "products",
    path: indexnoNCxus22FMeta?.path ?? "/products",
    meta: indexnoNCxus22FMeta || {},
    alias: indexnoNCxus22FMeta?.alias || [],
    redirect: indexnoNCxus22FMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: sewmeeUWhuuurTT5Meta?.name ?? "sewmee",
    path: sewmeeUWhuuurTT5Meta?.path ?? "/sewmee",
    meta: sewmeeUWhuuurTT5Meta || {},
    alias: sewmeeUWhuuurTT5Meta?.alias || [],
    redirect: sewmeeUWhuuurTT5Meta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/sewmee.vue").then(m => m.default || m)
  },
  {
    name: signinXeDspQ7o5hMeta?.name ?? "signin",
    path: signinXeDspQ7o5hMeta?.path ?? "/signin",
    meta: signinXeDspQ7o5hMeta || {},
    alias: signinXeDspQ7o5hMeta?.alias || [],
    redirect: signinXeDspQ7o5hMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signoutSwGXlnxLwXMeta?.name ?? "signout",
    path: signoutSwGXlnxLwXMeta?.path ?? "/signout",
    meta: signoutSwGXlnxLwXMeta || {},
    alias: signoutSwGXlnxLwXMeta?.alias || [],
    redirect: signoutSwGXlnxLwXMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: signupaqSlB33LrIMeta?.name ?? "signup",
    path: signupaqSlB33LrIMeta?.path ?? "/signup",
    meta: signupaqSlB33LrIMeta || {},
    alias: signupaqSlB33LrIMeta?.alias || [],
    redirect: signupaqSlB33LrIMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupDfM7VvnKJqMeta?.name ?? "suppliers-signup",
    path: signupDfM7VvnKJqMeta?.path ?? "/suppliers/signup",
    meta: signupDfM7VvnKJqMeta || {},
    alias: signupDfM7VvnKJqMeta?.alias || [],
    redirect: signupDfM7VvnKJqMeta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/suppliers/signup.vue").then(m => m.default || m)
  },
  {
    name: terms1vmjMdRxP9Meta?.name ?? "terms",
    path: terms1vmjMdRxP9Meta?.path ?? "/terms",
    meta: terms1vmjMdRxP9Meta || {},
    alias: terms1vmjMdRxP9Meta?.alias || [],
    redirect: terms1vmjMdRxP9Meta?.redirect || undefined,
    component: () => import("/var/repos/inter5/sewmee/appcnf/shop/pages/terms.vue").then(m => m.default || m)
  }
]